import axios from "axios"

import { setupInterceptorsTo } from "./interceptors"

const api = setupInterceptorsTo(
  axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
    headers: {
      "Access-Control-Allow-Origin": process.env.REACT_APP_API_URL
    }
  })
)

export default api
