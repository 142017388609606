import Keycloak from "keycloak-js"

const keycloakConfig = {
  realm: process.env.REACT_APP_KEYCLOAK_REALM || "",
  url: process.env.REACT_APP_KEYCLOAK_URL || "",
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID || "",
}

const _kc = new Keycloak(keycloakConfig)

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
  _kc
    .init({
      onLoad: "check-sso",
      silentCheckSsoRedirectUri:
        window.location.origin + "/silent-check-sso.html",
      pkceMethod: "S256",
    })
    .then((authenticated) => {
      if (authenticated) {
        onAuthenticatedCallback()
      } else {
        console.warn("User is not authenticated")
        doLogin()
      }
    })
    .catch(console.error)
}

const doLogin = _kc.login

const doLogout = _kc.logout

const getToken = () => _kc.token

const isLoggedIn = () => !!_kc.token

const updateToken = (successCallback) =>
  _kc.updateToken(5).then(successCallback).catch(doLogin)

const getUsername = () => _kc.tokenParsed?.name
const getEmail = () => _kc.tokenParsed?.email

const hasRealmRole = (roles) => roles.some((role) => _kc.hasRealmRole(role))
const hasClientRole = (roles) => roles.some((role) => _kc.hasResourceRole(role))

const AuthService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  getEmail,
  hasRealmRole,
  hasClientRole,
}

export default AuthService
