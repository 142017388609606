import { Box, IconButton, Modal, Typography } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { modalStyles } from "./modal.styles"

export const BasicModal = ({ open, onClose, modalTitle, children }) => {
  return (
    <>
      <Modal open={open} onClose={onClose} title={modalTitle}>
        <Box sx={modalStyles.wrapper}>
          <Box sx={modalStyles.header}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              align="center"
            >
              {modalTitle}
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={onClose}
              aria-label="close"
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                margin: "8px",
                zIndex: 1,
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          {children}
        </Box>
      </Modal>
    </>
  )
}
