import React, { useEffect, useState } from "react";
import { getHistory } from "./helpers/get-history";
import { Loader } from "@ui/loader";
import { historyTableKeyMapping } from "./helpers/table-columns";
import { Configs } from "@interfaces/configs";
import { HistoryTable } from "./table/table";

export const History = () => {
  const [configs, setConfigs] = useState<Configs[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [sortConfig, setSortConfig] = useState<{ key: string; direction: "asc" | "desc" } | null>(null);
  const [filteredConfigs, setFilteredConfigs] = useState<Configs[] | null>(null);
  const [filters, setFilters] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getHistory();
        setConfigs(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        return Promise.reject(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    let sortedConfigs = configs ? [...configs] : [];

    if (sortConfig && sortedConfigs) {
      sortedConfigs.sort((a, b) => {
        const aRow = a[sortConfig.key];
        const bRow = b[sortConfig.key];

        const valueA = typeof aRow == "object" ? aRow["value"] / aRow["scaleFactor"] : a[sortConfig.key];
        const valueB = typeof bRow == "object" ? bRow["value"] / bRow["scaleFactor"] : b[sortConfig.key];

        if (valueA < valueB) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (valueA > valueB) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }

    const filtered = sortedConfigs.filter((config) => {
      return Object.keys(filters).every((key) => {
        if (!filters[key]) return true
        if (!config[key]) return false
        const value = typeof config[key] == "object" ? config[key]["value"] / config[key]["scaleFactor"] : config[key]

        return value.toString().toLowerCase().includes(filters[key].toLowerCase());
          });
    });

    setFilteredConfigs(filtered);
  }, [configs, sortConfig, filters]);

  const getTableColumnsName = (apiKey: string) => {
    return historyTableKeyMapping[apiKey] || apiKey;
  };

  const formatDateTime = (datetime: string) => {
    const date = new Date(datetime);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };


  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSort = (key: string) => {
    let direction: "asc" | "desc" = "asc";

    if (sortConfig?.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const handleFilterChange = (key: string, value: string) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const indexOfLastRecord = (page + 1) * rowsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - rowsPerPage;
  const currentRecords = filteredConfigs?.slice(indexOfFirstRecord, indexOfLastRecord);
  const lengthOfRecords = filteredConfigs?.length || 0;

  return (
    <>
      {loading ? (
        <Loader />
      ) : configs !== null ? (
        <HistoryTable
          configs={configs}
          getTableColumnsName={getTableColumnsName}
          currentRecords={currentRecords}
          formatDateTime={formatDateTime}
          lengthOfRecords={lengthOfRecords}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleSort={handleSort}
          sortConfig={sortConfig}
          handleFilterChange={handleFilterChange}
          filters={filters}
        />
      ) : null}
    </>
  );
};
