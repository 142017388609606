import api from "@services/api-service/api-service"

export const getConfigurations = async () => {
  try {
    const response = await api.get("/configurations/file/")
    return response.data
  } catch (error: any) {
    return Promise.reject(error)
  }
}
