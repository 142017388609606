import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UpdateConfigurationsForm } from "@pages/configurations/form";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  CardMedia,
} from "@mui/material";
import { RemoteViewIFrame } from "@pages/configurations/remote-view";
import { getConfigurations } from "./helpers/get-configurations";
import { formatCamelCase } from "./helpers/configurations-names";
import { Configs, ConfigurationDetail } from "@interfaces/configs";
import { BasicModal } from "@templates/modal/modal";
import { Loader } from "@ui/loader";
import { isTokenExpired } from "@services/access-token-service/access-token-checker";
import { ExpireAt } from "@interfaces/expire-at";

import defaultIcon from "@assets/media/default.png";
import icons from './icons';

interface GroupedConfigs {
  [key: string]: {
    Camera1?: ConfigurationDetail;
    Camera2?: ConfigurationDetail;
    Other?: ConfigurationDetail;
  };
}

export const Configurations = () => {
  const navigate = useNavigate();
  const [configs, setConfigs] = useState<Configs | null>(null);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);

  const [expireAt, setExpireAt] = useState<ExpireAt>(() => {
    return localStorage.getItem("expireAt") || null;
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getConfigurations();
        setConfigs(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        return Promise.reject(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (isTokenExpired(expireAt)) {
      localStorage.removeItem("expireAt");
      navigate("/");
    }
  }, [openModal]);

  const getUserFriendlyName = (apiKey: string) => {
    return formatCamelCase(apiKey);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleModalOpen = () => {
    setOpenModal(true);
  };

  const groupCameraConfigs = (configs: Configs): { [group: string]: GroupedConfigs } => {
    const groupedByGroups: { [group: string]: GroupedConfigs } = {};

    Object.keys(configs).forEach((key) => {
      const baseKey = key.replace(/(Camera1|Camera2)/, "");
      const group = configs[key].group || "Other";

      if (!groupedByGroups[group]) {
        groupedByGroups[group] = {};
      }
      if (!groupedByGroups[group][baseKey]) {
        groupedByGroups[group][baseKey] = {};
      }

      if (key.includes("Camera1")) {
        groupedByGroups[group][baseKey].Camera1 = configs[key];
      } else if (key.includes("Camera2")) {
        groupedByGroups[group][baseKey].Camera2 = configs[key];
      } else {
        groupedByGroups[group][baseKey].Other = configs[key];
      }
    });

    return groupedByGroups;
  };

  const transformValue = (value: number, scaleFactor: number): number => {
    return value / scaleFactor;
  };

  const groupedByGroups = configs ? groupCameraConfigs(configs) : {};

  const getIconForConfig = (name: string | undefined) => {
    try {
      if (name === undefined) return defaultIcon
      return icons[name.replace(/(Camera1|Camera2)/, "")];
    } catch (error) {
      return defaultIcon;
    }
  };

  return (
    <Box mt={2}>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <RemoteViewIFrame />
        </Grid>
        <Grid item xs={4}>
          <Paper elevation={3} style={{ minHeight: "600px" }}>
            <Typography variant="h6" align="center">
              Camera configurations
            </Typography>
            {loading ? (
              <Loader />
            ) : configs !== null ? (
              <div>
                <TableContainer sx={{ mb: 3 }}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{ border: 1, borderColor: "grey.400", textAlign: "center" }}
                        >
                          Icon
                        </TableCell>
                        <TableCell
                          sx={{ border: 1, borderColor: "grey.400", textAlign: "center" }}
                        >
                          Name
                        </TableCell>
                        <TableCell
                          sx={{ border: 1, borderColor: "grey.400", textAlign: "center" }}
                        >
                          CAM1
                        </TableCell>
                        {Object.values(groupedByGroups).some((group) =>
                          Object.values(group).some((data) => data.Camera2)
                        ) && (
                          <TableCell
                            sx={{ border: 1, borderColor: "grey.400", textAlign: "center" }}
                          >
                            CAM2
                          </TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.entries(groupedByGroups).map(([groupName, groupConfigs]) => (
                        <>
                          {/* Group name row */}
                          <TableRow key={groupName}>
                            <TableCell
                              colSpan={4}
                              sx={{ textAlign: "center", backgroundColor: "grey.200", fontWeight: "bold" }}
                            >
                              {groupName}
                            </TableCell>
                          </TableRow>

                          {Object.entries(groupConfigs).map(([baseKey, data]) => {
                            const isShared = !data.Camera1 && !data.Camera2 && data.Other;
                            const iconName =
                              data.Other?.name || data.Camera1?.name || data.Camera2?.name;

                            return (
                              <TableRow key={baseKey}>
                                <TableCell
                                  sx={{
                                    border: 1,
                                    borderColor: "grey.400",
                                    textAlign: "center",
                                  }}
                                >
                                  <CardMedia
                                    sx={{ height: 30 }}
                                    image={getIconForConfig(iconName)}
                                    title={iconName}
                                  />
                                </TableCell>

                                <TableCell
                                  sx={{ border: 1, borderColor: "grey.400", textAlign: "center" }}
                                >
                                  {getUserFriendlyName(
                                    data.Other?.name || data.Camera1?.name || data.Camera2?.name
                                  )}
                                </TableCell>

                                {isShared ? (
                                  <TableCell
                                    colSpan={2}
                                    sx={{
                                      border: 1,
                                      borderColor: "grey.400",
                                      textAlign: "center",
                                    }}
                                  >
                                    {transformValue(
                                      data.Other?.value,
                                      data.Other?.scaleFactor
                                    )}
                                  </TableCell>
                                ) : (
                                  <>
                                    <TableCell
                                      sx={{
                                        border: 1,
                                        borderColor: "grey.400",
                                        textAlign: "center",
                                      }}
                                    >
                                      {data.Camera1
                                        ? transformValue(
                                            data.Camera1.value,
                                            data.Camera1.scaleFactor
                                          )
                                        : "-"}
                                    </TableCell>

                                    {Object.values(groupedByGroups).some((group) =>
                                      Object.values(group).some((data) => data.Camera2)
                                    ) && (
                                      <TableCell
                                        sx={{
                                          border: 1,
                                          borderColor: "grey.400",
                                          textAlign: "center",
                                        }}
                                      >
                                        {data.Camera2
                                          ? transformValue(
                                              data.Camera2.value,
                                              data.Camera2.scaleFactor
                                            )
                                          : "-"}
                                      </TableCell>
                                    )}
                                  </>
                                )}
                              </TableRow>
                            );
                          })}
                        </>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleModalOpen}
                  sx={{ display: "block", margin: "0 auto" }}
                >
                  Update Configurations
                </Button>
                <BasicModal
                  open={openModal}
                  onClose={handleModalClose}
                  modalTitle="Update configurations"
                >
                  <UpdateConfigurationsForm
                    handleModalClose={handleModalClose}
                    setConfigs={setConfigs}
                    configs={configs}
                  />
                </BasicModal>
              </div>
            ) : null}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};
