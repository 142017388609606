export const keyMapping = {
  BinaryOrColour: "Binary or Colour",
  ColourLearn: "Colour Learn",
  ConnectLeaves: "Connect Leaves",
  DetectionMode: "Detection Mode",
  CameraOffset: "Camera Offset",
  PlantAskew: "Plant Askew",
  CameraAngleCamera1: "Camera Angle",
  CameraDeltaRowDistanceCamera1: "Camera Delta Row Distance",
  CameraNumberOfDeltaRowsCamera1: "Camera Number of Delta Rows",
  CameraNumberOfRowsCamera1: "Camera Number of Rows",
  CameraRowDistanceCamera1: "Camera Row Distance",
  CameraOffsetCamera1: "Camera Offset",
  CameraAngleCamera2: "Camera Angle",
  CameraDeltaRowDistanceCamera2: "Camera Delta Row Distance",
  CameraNumberOfDeltaRowsCamera2: "Camera Number of Delta Rows",
  CameraNumberOfRowsCamera2: "Camera Number of Rows",
  CameraRowDistanceCamera2: "Camera Row Distance",
  CameraHeight: "Camera Height",
  GreenValue: "Green Value",
  PlantSize: "Plant Size",
  QualityThreshold: "Quality Threshold",
}

export const formatCamelCase = (key) => key
        .replace(/Camera[12]/g, '').trim()
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
        .trim();
