import { createTheme } from "@mui/material"

/**
 main blue  #0A2463
 secondary blue #3E92CC
 ghost white #FFFAFF
 red #D8315B
 black #1E1B18
 */

export const theme = createTheme({
  palette: {
    primary: {
      main: "#0A2463",
    },
    secondary: {
      main: "#3E92CC",
    },
    error: {
      main: "#D8315B",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          margin: 0,
          height: "100%",
          backgroundColor: "#FFFAFF",
        },
        "#root": {
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          paddingTop: 64,
        },
        form: {
          "& > .MuiTextField-root": {
            marginBottom: 7,
          },

          html: {
            height: "100%",
          },
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },

    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#0A2463",
          height: 64,
        },
      },
    },
  },
})
