import { BrowserRouter, Routes, Route } from "react-router-dom"
import { Layout } from "@templates/layout"
import { Main } from "@pages/main"
import { Configurations } from "@pages/configurations"
import { History } from "@pages/history"
import { AccessTokenProvider } from "@services/access-token-service/access-token-service"

export const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<Main />} />
          <Route
            path="/configurations/:machineSerialNo"
            element={
              <AccessTokenProvider>
                <Configurations />
              </AccessTokenProvider>
            }
          />
          <Route path="/history" element={<History />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}
