import { CssBaseline, ThemeProvider } from "@mui/material/"
import { theme } from "./app.theme"
import { Router } from "@router/router"
export const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router />
    </ThemeProvider>
  )
}
