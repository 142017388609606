import { createRoot } from "react-dom/client"
import { App } from "./components/app/app"
import UserService from "@services/authentication-service/auth-service"

const renderApp = () => {
  const root = createRoot(document.getElementById("root")!)
  root.render(<App />)
}

UserService.initKeycloak(renderApp)
