import BinaryOrColour from "@assets/media/BinaryOrColour.png";
import CameraAngle from '@assets/media/CameraAngle.png';
import DeltaRowDistance from '@assets/media/DeltaRowDistance.png';
import CameraHeight from '@assets/media/CameraHeight.png';
import NumberOfDeltaRows from '@assets/media/NumberOfDeltaRows.png';
import NumberOfRows from '@assets/media/NumberOfRows.png';
import CameraOffset from '@assets/media/CameraOffset.png';
import RowDistance from '@assets/media/RowDistance.png';
import ConnectLeaves from '@assets/media/ConnectLeaves.png';
import DetectionMode from '@assets/media/DetectionMode.png';
import GreenValue from '@assets/media/GreenValue.png';
import PlantAskew from '@assets/media/PlantAskew.png';
import PlantSize from '@assets/media/PlantSize.png';
import QualityThreshold from '@assets/media/QualityThreshold.png';

const icons = {
  BinaryOrColour: BinaryOrColour,
  CameraAngle: CameraAngle,
  DeltaRowDistance: DeltaRowDistance,
  CameraHeight: CameraHeight,
  NumberOfDeltaRows: NumberOfDeltaRows,
  NumberOfRows: NumberOfRows,
  CameraOffset: CameraOffset,
  RowDistance: RowDistance,
  ConnectLeaves: ConnectLeaves,
  DetectionMode: DetectionMode,
  GreenValue: GreenValue,
  PlantAskew: PlantAskew,
  PlantSize: PlantSize,
  QualityThreshold: QualityThreshold,
};

export default icons;