import UserService from "@services/authentication-service/auth-service"

const onRequest = (config) => {
  const callback = () => {
    config.headers.Authorization = `Bearer ${UserService.getToken()}`
    return Promise.resolve(config)
  }
  return UserService.updateToken(callback)
    .then(callback)
    .catch((error) => {
      console.error(error)
      return config
    })
}

const onRequestError = (error) => {
  return Promise.reject(error)
}

const onResponse = (response) => {
  return response
}

const onResponseError = async (error) => {
  if (error.response) {
    if (error.response.status === 500) {
      console.log("Status 500 error")
    }

    if (
      error.response.data.message &&
      error.response.data.message.includes("JWTError occurred:")
    ) {
      UserService.doLogout()
    }
  }

  return Promise.reject(error)
}

export const setupInterceptorsTo = (axiosInstance) => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError)
  axiosInstance.interceptors.response.use(onResponse, onResponseError)
  return axiosInstance
}
