import api from "@services/api-service/api-service"

export const setConfigurations = async (data) => {
  try {
    const response = await api.post("/configurations/file/", data)

    return response
  } catch (error: any) {
    return Promise.reject(error)
  }
}
