import { styled } from "@mui/material"

export const Form = styled("form")({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gap: "10px",

  "& > button": {
    gridColumn: "1 / -1",
    width: "100%",
  },

  "& > h4": {
    margin: 0,
  },

  "& .MuiTextField-root": {
    fontSize: "14px",
    "& .MuiFormLabel-root": {
      fontSize: "14px",
    },
    "& .MuiInputBase-input": {
      fontSize: "14px",
      height: "24px",
    },
  },
});
