import { createContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { AccessTokenContextType } from "@interfaces/access-token-context"
import { AccessTokenProviderProps } from "@interfaces/access-token-provider"
import { isTokenExpired } from "./access-token-checker"
import { ExpireAt } from "@interfaces/expire-at"

export const AccessTokenContext = createContext<
  AccessTokenContextType | undefined
>(undefined)

export function AccessTokenProvider({ children }: AccessTokenProviderProps) {
  const navigate = useNavigate()

  const [expireAt, setExpireAt] = useState<ExpireAt>(() => {
    return localStorage.getItem("expireAt") || null
  })

  useEffect(() => {
    if (isTokenExpired(expireAt)) {
      localStorage.removeItem("expireAt")
      navigate("/")
    }
  })

  return (
    <AccessTokenContext.Provider value={{ expireAt, setExpireAt }}>
      {children}
    </AccessTokenContext.Provider>
  )
}
