import { Container } from "@mui/material"

import { Button, Typography } from "@mui/material"
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined"
import * as Styled from "./header.styles"
import AuthService from "@services/authentication-service/auth-service"
import { useNavigate } from "react-router-dom"
import logo from "@assets/media/lemken_logo.svg"

export const Header = () => {
  const navigate = useNavigate()
  const handleLogout = () => {
    AuthService.doLogout()
  }

  const handleMainPageClick = () => {
    navigate("/")
  }

  const handleHistoryClick = () => {
    navigate("/history")
  }
  return (
    <Styled.Header>
      <Container maxWidth="xl">
        <Styled.LeftPart>
          <Styled.LogoContainer
            sx={{ mr: 3 }}
          >
            <Styled.LogoImage alt="logo" src={logo}/>
          </Styled.LogoContainer>
          <Button
            startIcon={<HomeOutlinedIcon/>}
            color="secondary"
            onClick={handleMainPageClick}
            sx={{ mr: 3 }}
          >
            Home
          </Button>
          <Button color="secondary" onClick={handleHistoryClick}>
            History
          </Button>
        </Styled.LeftPart>
        <Styled.RightPart>
          <Typography color="#FFFAFF" sx={{ mr: 2 }}>
            Welcome {AuthService.getUsername()}
          </Typography>
          <Button color="secondary" onClick={handleLogout}>
            Logout
          </Button>
        </Styled.RightPart>
      </Container>
    </Styled.Header>
  )
}
