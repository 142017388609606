import { FC, useMemo } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  TableSortLabel,
} from "@mui/material";

import { HistoryTableProps } from "@interfaces/history-table";
import { ConfigurationDetail, PersonalizedConfigs } from "@interfaces/configs";

const groupByDeviceId = (configs: PersonalizedConfigs[]) => {
  const grouped: { [key: string]: PersonalizedConfigs[] } = {};

  configs.forEach((config) => {
    const deviceId = config.device_id;
    if (!grouped[deviceId]) {
      grouped[deviceId] = [];
    }
    grouped[deviceId].push(config);
  });

  return grouped;
};

export const HistoryTable: FC<HistoryTableProps> = ({
  configs,
  getTableColumnsName,
  currentRecords,
  formatDateTime,
  lengthOfRecords,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSort,
  sortConfig,
  handleFilterChange,
  filters,
}) => {
  if (!configs || configs.length === 0) {
    return (
      <Paper sx={{ width: "100%", mb: 3, mt: 3 }}>
        <TableContainer>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontSize: "11px", fontWeight: "medium" }}>
                  No configurations data available
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </Paper>
    );
  }

  const columns: string[] = useMemo(() => {
    const keySet: Set<string> = new Set();
    configs.forEach((obj) => {
      Object.keys(obj).forEach((key) => keySet.add(key.toString()));
    });

    return Array.from(keySet);
  }, [configs]);

  const groupedConfigs = useMemo(() => groupByDeviceId(configs), [configs]);

  const getColumnValue = (key: string, config: PersonalizedConfigs): string | number => {
    if (key === "created_at") {
      return formatDateTime(config[key] as unknown as string);
    } else if (typeof config[key] === "object" && config[key] !== null) {
      const configDetail = config[key] as ConfigurationDetail;
      return configDetail.value / configDetail.scaleFactor;
    } else {
      return config[key] !== undefined && config[key] !== null ? config[key].toString() : "-";
    }
  };

  const hasChanged = (key: string, currentConfig: PersonalizedConfigs, previousConfig: PersonalizedConfigs): boolean => {
    const ignoredFields = ["user", "device_id", "serial_number", "created_at", "_id"];

    if (ignoredFields.includes(key) || !previousConfig) {
      return false;
    }

    const currentValue = getColumnValue(key, currentConfig);
    const previousValue = getColumnValue(key, previousConfig);

    return currentValue !== previousValue;
  };

  return (
    <Paper sx={{ width: "100%", mb: 3, mt: 3 }}>
      <TableContainer>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((key, index) => (
                <TableCell
                  key={`${key}-${index}`}
                  sx={{
                    fontSize: "11px",
                    fontWeight: "medium",
                  }}
                >
                  <TableSortLabel
                    active={sortConfig?.key === key}
                    direction={sortConfig?.key === key ? sortConfig.direction : "asc"}
                    onClick={() => handleSort(key)}
                  >
                    {getTableColumnsName(key)}
                  </TableSortLabel>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={filters[key] || ""}
                    onChange={(e) => handleFilterChange(key, e.target.value)}
                    placeholder={`Filter ${getTableColumnsName(key)}`}
                    sx={{ marginTop: 1 }}
                  />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {currentRecords?.map((config, rowIndex) => {
              const previousConfig = groupedConfigs[config.device_id]?.[1];
              return (
                <TableRow key={`${config._id}-${rowIndex}`}>
                  {columns.map((key, colIndex) => (
                    <TableCell
                      key={`${key}-${colIndex}`}
                      sx={{
                        fontSize: "11px",
                        backgroundColor: hasChanged(key, config, previousConfig)
                          ? "rgba(255, 0, 0, 0.1)"
                          : "inherit",
                      }}
                    >
                      {getColumnValue(key, config)}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={lengthOfRecords}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
