export const historyTableKeyMapping = {
  _id: "ID",
  bBinaryOrColour: "Binary or Colour",
  bColourLearn: "Colour Learn",
  bConnectLeaves: "Connect Leaves",
  bDetectionMode: "Detection Mode",
  s16CameraOffset: "Camera Offset",
  s16PlantAskew: "Plant Askew",
  u8Camera1Angle: "Camera Angle",
  u8Camera1DeltaRowDistance: "Camera Delta Row Distance",
  u8Camera1NumberOfDeltaRows: "Camera Number of Delta Rows",
  u8Camera1NumberOfRows: "Camera Number of Rows",
  u8Camera1RowDistance: "Camera Row Distance",
  u8CameraHeight: "Camera Height",
  u8GreenValue: "Green Value",
  u8PlantSize: "Plant Size",
  u8QualityThreshold: "Quality Threshold",
  user: "User",
  device_id: "Device ID",
  serial_number: "Serial Number",
  created_at: "Created at",
}
