import api from "@services/api-service/api-service"

export const getHistory = async () => {
  try {
    const response = await api.get("/configurations/history/")
    return response.data
  } catch (error: any) {
    return Promise.reject(error)
  }
}
