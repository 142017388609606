import { ReactNode, useState, useEffect, useMemo } from "react"
import { useForm, useWatch } from "react-hook-form"
import {
  Box,
  Button,
  Grid,
  TextField,
  ToggleButton,
  ButtonGroup,
  ToggleButtonGroup,
  Typography,
  Switch,
} from "@mui/material";
import { Form } from "./styles";
import { Configs, ConfigurationDetail } from "@interfaces/configs";
import { setConfigurations } from "@pages/configurations/helpers/set-configurations";
import {formatCamelCase, keyMapping} from "@pages/configurations/helpers/configurations-names";
import { getLastConfigurations } from "@pages/configurations/helpers/get-last-configurations";

const convertWatchedValuesArrayToObject = (keys, values) => {
  return Object.fromEntries(
    keys.map((key, index) => [key, values[index]])
  );
};

interface UpdateConfigsFormProps {
  handleModalClose: () => void;
  setConfigs: (configs: Configs) => void;
  configs: Configs;
}

export const UpdateConfigurationsForm = ({
  handleModalClose,
  setConfigs,
  configs,
}: UpdateConfigsFormProps) => {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
    control
  } = useForm<Configs>({
    defaultValues: Object.fromEntries(
      Object.keys(configs).map((key) => [
        key,
        {
          ...configs[key],
          value: configs[key].value / configs[key].scaleFactor,
        },
      ])
    ) as Configs,
  });

  const [submitting, setSubmitting] = useState(false);
  const [alignment, setAlignment] = useState("Camera1");
  const [camera1Exists, setCamera1Exists] = useState(false);
  const [camera2Exists, setCamera2Exists] = useState(false);
  const [lastConfigurations, setLastConfigurations] = useState<Configs[]>([]);

  const [changedFields, setChangedFields] = useState<Set<string>>(new Set());
  const [inputErrors, setInputErrors] = useState<{ [key: string]: boolean }>({});

  const watchedValuesArray = useWatch({ control, name: Object.keys(configs).map((key) => key) });
  const watchedValues = useMemo(() => convertWatchedValuesArrayToObject(Object.keys(configs), watchedValuesArray), [watchedValuesArray]);


  useEffect(() => {
    const newErrors: { [key: string]: boolean } = {};

    Object.keys(configs).forEach((key) => {
      const detail = configs[key] as ConfigurationDetail;
      const value = watchedValues[detail.name]?.value || 0;
      const scaledValue = value * detail.scaleFactor;

      if (scaledValue < detail.minValue || scaledValue > detail.maxValue) {
        newErrors[key] = true;
      } else {
        const stepSize = 1 / detail.scaleFactor;
        if (Math.round((value - detail.minValue) / stepSize * 10) / 10 !== Math.floor((value - detail.minValue) / stepSize)) {
          newErrors[key] = true;
        } else {
          newErrors[key] = false;
        }
      }
    });

    setInputErrors(newErrors);
  }, [watchedValues, configs]);


  useEffect(() => {
    const camera1Available = Object.keys(configs).some((key) =>
      key.includes("Camera1")
    );
    const camera2Available = Object.keys(configs).some((key) =>
      key.includes("Camera2")
    );

    setCamera1Exists(camera1Available);
    setCamera2Exists(camera2Available);

    if (camera1Available) {
      setAlignment("Camera1");
    } else if (camera2Available) {
      setAlignment("Camera2");
    }

    fetchLastConfigurations();
  }, [configs]);

  useEffect(() => {
    Object.keys(configs).forEach((key) => {
      const detail = configs[key] as ConfigurationDetail;
      register(`${detail.name}.value`, {
        value: detail.value / detail.scaleFactor,
        setValueAs: (v) => v ?? detail.value / detail.scaleFactor,
      });
    });
  }, [register, configs]);

  const fetchLastConfigurations = async () => {
    try {
      const configurations = await getLastConfigurations();
      setLastConfigurations(configurations);
    } catch (error) {
      console.error("Error fetching last configurations:", error);
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
  };

  const handleLoadConfiguration = (configuration: Configs) => {
    const transformedConfig: Configs = Object.fromEntries(
      Object.entries(configuration)
        .filter(
          ([key, value]) =>
            typeof value === "object" && value !== null && "name" in value
        )
        .map(([key, value]) => [
          value.name,
          {
            ...value,
            value: value.value / value.scaleFactor,
          },
        ])
    ) as Configs;

    reset(transformedConfig);
    highlightChangedFields(transformedConfig);
  };

  const highlightChangedFields = (newConfig: Configs) => {
    const changed = new Set<string>();

    Object.keys(configs).forEach((key) => {
      const currentValue = configs[key].value / configs[key].scaleFactor;
      const newValue = newConfig[key] ? newConfig[key].value : configs[key].value; // already scaled

      if (currentValue !== newValue) {
        changed.add(key);
      }
    });

    setChangedFields(changed);
  };

  const transformInputs = (inputs: Configs) => {
    const transformed: { [key: string]: any } = {};

    Object.keys(configs).forEach((key) => {
      const configKeyName = configs[key]["key"]
      transformed[configKeyName] = inputs[configs[key].name].value * configs[key].scaleFactor;
    });

    return transformed;
  };

  const onFormSubmit = async (inputs: Configs) => {
    try {
      setSubmitting(true);

      const transformedInputs = transformInputs({
        ...inputs,
      });
      const res = await setConfigurations(transformedInputs);
      handleModalClose();
      setConfigs(res.data);
    } catch (error) {
      console.error("Error setting Configs:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const getUserFriendlyName = (key: string) => {
    return formatCamelCase(key)
  };

  const handleAlignmentChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  const renderTextField = (key: string, detail: ConfigurationDetail) => {
    const name = getUserFriendlyName(key);
    const isBooleanSwitch =
      detail.minValue === 0 && detail.maxValue === 1 && detail.scaleFactor === 1;

    register(`${detail.name}.value`, { valueAsNumber: true });

    const isOldConfig = detail.minValue === 0 && detail.maxValue === 0;
    const isChanged = changedFields.has(key);

    if (isBooleanSwitch) {
      return (
        <Box key={key} sx={{ display: "flex", alignItems: "center" }}>
          <Switch
            id={key}
            {...register(`${detail.name}.value`, { valueAsNumber: true })}
            defaultChecked={detail.value === 1}
          />
          <Typography variant="body2" sx={{ ml: 1 }}>
            {name}
          </Typography>
        </Box>
      );
    }

    return (
      <TextField
        key={key}
        id={key}
        {...register(`${detail.name}.value`, { valueAsNumber: true })}
        error={inputErrors[key]}
        helperText={
          errors[key]?.value?.message
            ? (errors[key]?.value?.message as ReactNode)
            : isOldConfig
            ? ""
            : `Min: ${detail.minValue / detail.scaleFactor}, Max: ${
                detail.maxValue / detail.scaleFactor
              }, Step: ${1 / detail.scaleFactor}`
        }
        label={name}
        variant="outlined"
        size="small"
        fullWidth
        InputLabelProps={{
          style: { fontSize: 14 },
        }}
        type="number"
        inputProps={
          isOldConfig
            ? {}
            : {
                min: detail.minValue / detail.scaleFactor,
                max: detail.maxValue / detail.scaleFactor,
                step: 1 / detail.scaleFactor,
              }
        }
        defaultValue={
          isOldConfig ? detail.value : detail.value / detail.scaleFactor
        }
        sx={{
          backgroundColor: isChanged ? "#ffebee" : "inherit",
        }}
      />
    );
  };

  return (
    <Box
       sx={{
        display: "flex",
        alignItems: "center",
        mb: 2,
        justifyContent: "center",
        position: "sticky",
        top: 0,
        zIndex: 1,
        backgroundColor: "white",
        padding: "10px",
      }}
    >
      <Form
        autoComplete="off"
        onSubmit={handleSubmit(onFormSubmit)}
        style={{ width: "75%", display: "flex", flexDirection: "column" }}
      >
        {camera1Exists && camera2Exists && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 2,
              justifyContent: "center",
            }}
          >
            <ToggleButtonGroup
              color="primary"
              value={alignment}
              exclusive
              onChange={handleAlignmentChange}
              aria-label="Camera selection"
            >
              <ToggleButton value="Camera1">Camera1</ToggleButton>
              <ToggleButton value="Camera2">Camera2</ToggleButton>
            </ToggleButtonGroup>
          </Box>
        )}

        {(camera1Exists || camera2Exists) && (
          <>
            <h4>Camera configurations</h4>
            <Grid container spacing={2} sx={{ mb: 3 }}>
              {Object.entries(configs).map(([key, value]) => {
                const detail = value as ConfigurationDetail;
                if (
                  (alignment === "Camera1" && key.includes("Camera1")) ||
                  (alignment === "Camera2" && key.includes("Camera2")) ||
                  (alignment === "Camera1" &&
                    key.includes("Camera") &&
                    !key.includes("Camera1") &&
                    !key.includes("Camera2"))
                ) {
                  return (
                    <Grid item xs={12} sm={6} key={key}>
                      {renderTextField(value.name, detail)}
                    </Grid>
                  );
                }
                return null;
              })}
            </Grid>
          </>
        )}

        <h4>Machine configurations</h4>
        <Grid container spacing={2} sx={{ mb: 3 }}>
          {Object.entries(configs).map(([key, value]) => {
            const detail = value as ConfigurationDetail;
            if (
              !key.includes("Camera1") &&
              !key.includes("Camera2") &&
              !key.includes("Camera")
            ) {
              return (
                <Grid item xs={12} sm={6} key={key}>
                  {renderTextField(value.name, detail)}
                </Grid>
              );
            }
            return null;
          })}
        </Grid>

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmitting || Object.values(inputErrors).some(value => value)}
            sx={{
              width: "100%",
              maxWidth: "200px",
            }}
          >
            Submit
          </Button>
        </Box>
      </Form>

      <Box sx={{ width: "25%", pl: 2 }}>
        <h4>Last Configurations</h4>
        {lastConfigurations.length > 0 ? (
          <ButtonGroup
            orientation="vertical"
            aria-label="Vertical button group"
            fullWidth
          >
            {lastConfigurations.map((config, index) => (
              <Button
                key={index}
                variant={index === 0 ? "contained" : "outlined"}
                color={index === 0 ? "secondary" : "primary"}
                onClick={() => handleLoadConfiguration(config)}
                sx={{
                  textTransform: "none",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="body2" sx={{ fontSize: 14 }}>
                    {index === 0
                      ? "Current Configuration"
                      : `Configuration ${index + 1}`}
                  </Typography>
                  <Typography variant="caption" sx={{ fontSize: 12 }}>
                    {formatDate(config.created_at || "")}
                  </Typography>
                </Box>
              </Button>
            ))}
          </ButtonGroup>
        ) : (
          <p>No configurations available</p>
        )}
      </Box>
    </Box>
  );
};
